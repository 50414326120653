import React from 'react'
import Navbar from '../../component/Navbar'
import { useState } from 'react'
import { Outlet, useNavigate, redirect } from 'react-router-dom'
import Modal from '../../component/Modal'
import Button from '../../component/Button'
import Pause from './../../assets/pause_circle.svg'
import { Dashboard_API_CALL } from '../../utilities/function'
import { toast } from 'react-toastify'
import Fade from '../../component/Fade'
import Disclaimer from '../../component/Disclaimer'
import { HamburgerMenu } from '../../component/Navbar'
import { useMediaQuery } from 'react-responsive';

const Dashboard = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const [openDeleteKey, setOpenDeleteKey] = useState(false);
  const [pauseKey, setPauseKey] = useState({
    modal: false,
    message: null,
    status: null
  });
  const [appStatus, setAppStatus] = useState(null);
  const [apiKey, setAPIKey] = useState(null);
  const [openHamburgerMenu, setOpenHamburgerMenu] = useState(false);
  const [apiSecret, setAPISecret] = useState(null);
  const [fetchAPI, setFetchAPI] = useState(false);
  const [openDisclaimer, setOpenDisclaimer] = useState(true);
  const [readMoreText, setReadMoreText] = useState("");
  const [openReadMore, setOpenReadMore] = useState(false);
  const navigate = useNavigate()
  const deleteApp = async () => {
    const data = {
      clientId: localStorage.clientId,
      apiKey: apiKey,
      apiSecret: apiSecret
    }
    const headers = {
      'Authorization': localStorage.accessToken,
      'Content-Type': 'application/json'
    }
    const response = await Dashboard_API_CALL('/delete-apps', 'delete', headers, null, data);
    if (response?.data?.data?.status !== 'success' && response?.data?.meta?.statusCode !== 'OK') {
      setOpenDeleteKey(false);
      return;
    }
    setFetchAPI(response.data);
    toast.success('App Deleted');
    setOpenDeleteKey(false);
    setFetchAPI(response.data)
    console.log(response);
  }

  const pauseApp = async (status) => {
    const headers = {
      'Authorization': localStorage.accessToken,
      'Content-Type': 'application/json'
    }
    const data = {
      apiKey,
      apiSecret,
      clientId: localStorage.clientId,
      status: status
    }
    const response = await Dashboard_API_CALL('/update-app-status', 'post', headers, null, data);
    if (response?.data?.meta?.statusCode !== 'OK') {
      setPauseKey((item) => ({ ...item, 'modal': false }));
      return;
    }
    setFetchAPI(response.data);
    if (status === 'active') {
      toast.success(`App Resumed`);
    } else {
      toast.success(`App Paused`);
    }
    setPauseKey((item) => ({ ...item, 'modal': false }));
    setOpenDeleteKey(false);
    setFetchAPI(response.data)

  }

  const DeleteModal = () => <Modal
    onClick={() => setOpenDeleteKey(false)}
    className={'p-5 lg:w-1/3 '}>
    <div className='flex flex-col gap-5'>
      <div
        className='flex-col flex gap-3'>
        <h2 className='text-base  font-bold text-Dark-Blue-80'>Are you sure you want to delete?</h2>
        <p className='text-Grey-50'>You are about to permanently delete the app. {appStatus === 'active' && "You can temporarily pause instead."}</p>
      </div>
      <div className='w-full flex items-center justify-center gap-5'>
        <button
          onClick={deleteApp}
          className='text-[#D92D20] rounded-md text-[16px] max-lg:py-4 font-bold border-[1px] py-2 px-4 border-[#D92D20]'>
          Yes, Delete
        </button>
        <Button
          onClick={() => setOpenDeleteKey(false)}
          className={'px-4'}>No, Don’t Delete</Button>
      </div>
      {appStatus === 'active' && <div
        onClick={() => pauseApp('inactive')}
        className='flex items-center justify-center text-center gap-2 cursor-pointer'>
        <img src={Pause} alt="" />
        <p className='font-semibold text-Primary-Blue-100'>Pause API Instead</p>
      </div>}
    </div>
  </Modal>

  // Pause Modal



  const PauseModal = ({ message }) => <Modal
    onClick={() => setOpenDeleteKey(false)}
    className={'p-5 lg:w-1/3 '}>
    <div className='flex flex-col gap-5'>
      <div className='flex-col flex gap-3 items-center justify-center'>
        <h2 className='text-base  font-bold text-Dark-Blue-80 text-center'>Are you sure you want to {message} App?</h2>
        {
          message !== 'Resume' &&
          <p className='text-Grey-50 w-2/3 text-center'>InvestRight Integration with the App will temporarily pause until you resume</p>
        }
      </div>
      <div className='w-full flex items-center justify-center gap-5'>
        <button
          onClick={() => pauseApp(pauseKey.status)}
          className='text-[#D92D20] rounded-md text-[16px] max-lg:py-4 font-bold border-[1px] py-2 px-4 border-[#D92D20]'>
          Yes, {message}
        </button>
        <Button
          onClick={() => setPauseKey((item) => ({ ...item, modal: false }))}
          className={'px-4'}>No, Don’t {message}</Button>
      </div>
    </div>
  </Modal>

  const ReadMore = ({ message }) => <Modal
    className={'p-5 lg:w-1/3 max-lg:w-4/5'}
    onClick={() => { setOpenReadMore(false) }}
  >
    <div className='flex flex-col gap-2 items-center justify-center flex-shrink flex-grow-0 flex-wrap break-all'>
      <h1 className='SubHeading'>Description</h1>
      <p className='text-base'>{message}</p>
      <Button
        onClick={() => { setOpenReadMore(false) }}
        className={'px-5'}
        text={'Close'}
      />
    </div>
  </Modal>

  if (!localStorage.imIsLoggedIn || localStorage.imIsLoggedIn === null || localStorage.imIsLoggedIn === undefined) {
    return window.location.href = '/oapi/v1/im/login'
  } else {
    return (
      <>
        {
          openReadMore && <ReadMore message={readMoreText} />}
        {
          openDisclaimer && <Disclaimer setOpenDisclaimer={setOpenDisclaimer} />
        }
        {
          openDeleteKey && <DeleteModal />
        }
        {
          pauseKey.modal && <PauseModal message={pauseKey.message} />
        }
        <section className='w-full h-screen bg-cover max-lg:h-[90vh] max-lg:w-[100vw] max-lg:overflow-x-hidden  min-h-screen bg-[#F4F7FA] bg-center bg-no-repeat'>
          <Navbar
            isLogin={localStorage.imIsLoggedIn}
            funcOpenHamburgerMenu={() => setOpenHamburgerMenu(!openHamburgerMenu)} />
          {isTabletOrMobile &&
            (openHamburgerMenu &&
              <HamburgerMenu />
            )
          }
          <Fade>
            <Outlet
              context={[setOpenDeleteKey, setAPISecret, setAPIKey, fetchAPI, setPauseKey, setFetchAPI, setAppStatus, setOpenReadMore, setReadMoreText]}
            />
          </Fade>
        </section>
      </>
    )
  }
}

export default Dashboard