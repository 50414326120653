import zero from './../assets/zero.svg';
import lightning from './../assets/lightning.svg';
import linked from './../assets/linked.svg';
import court from './../assets/court.svg';
import settings from './../assets/settings.svg';
import charts from './../assets/charts.svg';
import Service from './../assets/Service.png';
import Individual from './../assets/Individual.png';
import Fintech from './../assets/Fintech.png';
import InvestRightLogo from './../assets/InvestRight.svg'


export const Name = "HDFC Securities";


export const NavbarLogo = InvestRightLogo;

export const Logo = InvestRightLogo;

export const InvestRight_UAT = 'https://uat-developer.hdfcsec.com/oapi/v1';
export const InvestRight_Production = 'https://developer.hdfcsec.com/oapi/v1';

export const disclaimerContent = [
    "9 out of 10 individual traders in equity Futures and Options Segment, incurred net losses.",
    "On an average, loss makers registered net trading loss close to ₹50,000.",
    "Over and above the net trading losses incurred, loss makers expended an additional 28 % of net trading losses as transaction costs.",
    "Those making net trading profits, incurred between 15 % to 50 % of such profits as transaction costs."
]




export const AuthoriseContent = [
    'Place,Modify and cancel order',
    'Access and view your holding , positons and transaction history',
    'View account balances and margins',
    'F&O, Option chain, Questionnaire and rating',
    'Access and view your profile'
];


export const inputConfigs = [
    { name: 'name', placeholder: 'Enter Merchant Name', label: 'Merchant Name' },
    { name: 'client_id', placeholder: 'Enter Client Id', label: 'Client ID' },
    { name: 'displayName', placeholder: 'Enter Display Name', label: 'Display Name' },
    { name: 'tps', placeholder: 'Enter TPS Value', label: 'TPS' },
    { name: 'callbackUrl', placeholder: 'Enter Callback URL', label: 'Redirect URL' },
    { name: 'tenant', placeholder: 'Enter Tenant', label: 'Tenant' },
    { name: 'status', placeholder: 'Enter Status', label: 'Status' }
];

export const TabsContent = [
    {
        img: Fintech,
        imgHeading: <span>Individual Trader or Developer</span>,
        contentHeading: <span>Maximize Trading Potential: Unveiling API Advantages for Traders & Developers</span>,
        content: [
            'Zero cost APIs allows you to experiment freely.',
            'Simple to use APIs allows developers of any skill level to connect effortlessly across a growing network of apps.',
            'Low Latency customisable APIs allows individuals to build fast, secure and modular trading systems'
        ]
    },
    {
        img: Individual,
        imgHeading: <span>Fintechs and Startups</span>,
        contentHeading: <span>Accelerate Innovation: Embrace our API for<br className='max-lg:hidden' /> Startups & Fintech</span>,
        content: [
            'Open your door to live market broadcasts, executing orders in real time, managing the user portfolio and many more.',
            'As a fintech startup, you can access our APIs, free of cost to build a full fledged innovative trading and investment platform.',
            // 'To know more about our API, Features & Access, you can reach out to us at openapi.care@hdfcsec.com'
        ]
    },
    // {
    //     img: Service,
    //     imgHeading: <span>Broking As A <br /> service</span>,
    //     contentHeading: <span>Upgrade Trading Systems: Fast and Secure<br className='max-lg:hidden' /> Tech Stack for Brokers</span>,
    //     content: [
    //         'Open your door to live market broadcasts, executing orders in real time, managing the user portfolio and many more.',
    //         'As a fintech startup, you can access our APIs, free of cost to build a full fledged innovative trading and investment platform.',
    //         'To know more about our API, Features & Access, you can reach out to us at openapi.care@hdfcsec.com'
    //     ]
    // }
];

export const FeatureCardContent = [
    {
        heading: 'Free APIs for All',
        text: 'Free InvestRight Trading APIs for traders, developers, and fintech startups.',
        img: zero
    },
    {
        heading: 'Fast, Simple and Seamless',
        text: 'Get lightning-fast and easy to use trading execution',
        img: lightning
    },
    {
        heading: 'Reliability You Can Trust',
        text: "Trade confidently, backed by InvestRight API's sturdy and dependable infrastructure.",
        img: court
    },
    {
        heading: 'Real-time Market Insights',
        text: 'Get market trends and opportunities with real-time data.',
        img: charts
    },
    {
        heading: 'User-Friendly Integration',
        text: 'User-friendly interface for integrations, catering to developers of all levels.',
        img: settings
    },
    {
        heading: 'Connected Apps Ecosystem',
        text: 'Access a growing network of apps optimized for InvestRight API',
        img: linked
    }
]

export const FeatureCardContent2 = [
    {
        heading: 'Trading API',
        subheading: 'Effortless trade execution',
        text: 'Trade easily with smooth transactions and precise execution for your investments.'
    },
    {
        heading: 'Position API',
        subheading: 'Transparent portfolio visibility',
        text: 'See your investments clearly and gain visibility and control for strategic portfolio moves.'
    },
    {
        heading: 'Holding API',
        subheading: 'Efficient asset tracking',
        text: 'Manage holdings easily and get an organized view of your assets for efficient monitoring'
    },
    {
        heading: 'GTT Order API',
        subheading: 'Flexible conditional orders',
        text: 'Customize and execute orders with precise, adaptable and tailored trading strategies '
    },
    {
        heading: 'Market Data API',
        isNew: true,
        subheading: 'Real-time market insights',
        text: 'Explore the market using real-time insights, trends, and empowered decision-making in trading.'
    },
    // {
    //     heading: 'Option Chain API',
    //     subheading: 'Comprehensive option data access',
    //     text: 'Access comprehensive data for insightful derivative analysis, enhancing your strategies.'
    // },

    // {
    //     heading: 'Cover & Basket Order API',
    //     subheading: 'Streamlined multiple-order handling',
    //     text: 'Simplify and optimize trading by effortlessly handling multiple orders in one sweep'
    // },
    {
        heading: 'Websockets',
        isNew: true,
        subheading: 'Continuous real-time market updates',
        text: 'Stay updated with continuous live data for informed and timely decision with real-time market updates'
    },
];

export const HelpBoxContent = [
    {
        icon: 'account_box',
        label: 'Forgot Login ID',
        link: 'https://ntrade.hdfcsec.com/pages/forgotUsername.jsp?reqFor=FL'
    },
    {
        icon: 'key',
        label: 'Forgot Password',
        link: 'https://ntrade.hdfcsec.com/help/passwordInstpage.jsp?'
    },
    {
        icon: 'lock_open',
        label: 'Unlock Account',
        link: 'https://ntrade.hdfcsec.com/pages/forgotUsername.jsp?reqFor=UL'
    },
    {
        icon: 'live_help',
        label: 'FAQs',
        link: 'https://www.hdfcsec.com/faq'
    },
    {
        icon: 'phone_in_talk',
        label: 'Contact Us',
        link: 'https://www.hdfcsec.com/contact-us'
    },
]

export const footerLinks = [
    {
        "title": "Newsroom",
        "links": [
            { "title": "Speaker profiles", "url": "https://www.hdfcsec.com/NewsRoom/MediaKit" },
            { "title": "Media Coverage", "url": "https://www.hdfcsec.com/NewsRoom/Print/1" },
            { "title": "Press Release", "url": "https://www.hdfcsec.com/NewsRoom/PressRelease/1" },
            { "title": "Media Contact", "url": "https://www.hdfcsec.com/NewsRoom/MediaContact" },
            { "title": "Training video", "url": "https://www.hdfcsec.com/products/demolisting" },
            { "title": "Top Management", "url": "https://www.hdfcsec.com/top-management" }
        ]
    },
    {
        "title": "New To Site?",
        "links": [
            { "title": "About Us", "url": "https://www.hdfcsec.com/article/about-us-1647" },
            { "title": "Careers", "url": "https://www.hdfcsec.com/Careers" },
            { "title": "Trouble in Login", "url": "https://www.hdfcsec.com/productpage/trouble-in-login" },
            { "title": "Generate Password", "url": "https://www.hdfcsec.com/productpage/online-password-generation" },
            { "title": "Awards and Accolades", "url": "https://www.hdfcsec.com/productpage/awards-accolades" },
            { "title": "Corporate Social Responsibility", "url": "https://www.hdfcsec.com/productpage/csr" },
            { "title": "Live On boarding webinar", "url": "https://attendee.gotowebinar.com/rt/1566508718154944781" },
            { "title": "Your Opinion", "url": "https://www.hdfcsec.com/DynamicForm/index/3845-88507" }
        ]
    },
    {
        "title": "Account Opening",
        "links": [
            { "title": "Resident Indian", "url": "https://www.hdfcsec.com/article/residentindian-1669" },
            { "title": "Documents Required", "url": "https://www.hdfcsec.com/article/documentsrequired-1794" },
            { "title": "NRI", "url": "https://www.hdfcsec.com/productpage/nri-investment-account" },
            { "title": "Know your Account Status", "url": "https://udn.trade.hdfcsec.com/" },
            { "title": "FAQ", "url": "https://www.hdfcsec.com/productpage/account-opening" }
        ]
    },
    {
        "title": "Important Information",
        "links": [
            { "title": "SEBI", "url": "https://www.sebi.gov.in/" },
            { "title": "BSE", "url": "https://www.bseindia.com/" },
            { "title": "NSE", "url": "https://www.nseindia.com/" },
            { "title": "MCX", "url": "https://www.mcxindia.com/home" },
            { "title": "NCDEX", "url": "https://www.ncdex.com/index.aspx" },
            { "title": "CDSL", "url": "https://www.cdslindia.com/index.html" },
            { "title": "NSDL", "url": "https://www.nsdl.co.in/" },
            { "title": "Privacy Policy", "url": "https://www.hdfcsec.com/article/privacypolicy-1688" },
            { "title": "MF and PMS Disclosure", "url": "https://www.hdfcsec.com/article/mutualfunddisclosure-3045" },
            { "title": "BSE Disclaimer", "url": "https://www.hdfcsec.com/article/bsedisclaimer-1690" },
            { "title": "Regulatory Information", "url": "https://www.hdfcsec.com/article/regulatoryinformation-1824" },
            { "title": "Forms", "url": "https://www.hdfcsec.com/productpage/form" },
            { "title": "List of Eligible Scrips", "url": "https://www.hdfcsec.com/list-of-eligible-scrips" },
            { "title": "Brokerage", "url": "https://www.hdfcsec.com/article/brokeragecharges-3044" },
            { "title": "Disclaimer", "url": "https://www.hdfcsec.com/article/disclaimer-1795" },
            { "title": "Scores", "url": "https://www.hdfcsec.com/productpage/scores" },
            { "title": "Margin Collection", "url": "https://www.hdfcsec.com/margin-regulation" },
            { "title": "Advisory for Investor", "url": "https://www.hdfcsec.com/advisory-for-investors" },
            { "title": "Internal Auction Policy", "url": "https://www.hdfcsec.com/compliance-hdfc-securities" },
            { "title": "NSE Holidays", "url": "https://www.hdfcsec.com/national-stock-exchange-nse-holidays" },
            { "title": "BSE Holidays", "url": "https://www.hdfcsec.com/bombay-stock-exchange-bse-holidays" },
            { "title": "Stock Market Holiday", "url": "https://www.hdfcsec.com/indian-share-market-holidays" },
            { "title": "Precaution for clients dealing in options", "url": "https://www.hdfcsec.com/hsl.images/Precautions%20for%20clients%20dealing%20in%20Options-202308161456311884031.pdf" },
            { "title": "Investor Awareness", "url": "https://www.hdfcsec.com/investor-awareness" },
            { "title": "Investment Advisory", "url": "https://www.hdfcsec.com/investment-advisory" },
            { "title": "CDSL e-Voting", "url": "https://evoting.cdslindia.com/Evoting/EvotingLogin" }
        ]
    },
    {
        "title": "HDFC Group",
        "links": [
            { "title": "HDFC Ltd", "url": "https://www.hdfc.com/" },
            { "title": "HDFC Bank", "url": "https://www.hdfcbank.com/" },
            { "title": "HDB Financial Services", "url": "https://www.hdbfs.com/" },
            { "title": "HDFC Life", "url": "https://www.hdfclife.com" },
            { "title": "HDFC ERGO", "url": "https://www.hdfcergo.com" },
            { "title": "HDFC AMC", "url": "https://www.hdfcfund.com/" },
            { "title": "HDFC Sales", "url": "https://www.hdfcsales.com/" }
        ]
    },
    {
        "title": "Knowledge Centre",
        "links": [
            { "title": "Blogs", "url": "https://www.hdfcsec.com/Blog/Listing/6033" }
        ]
    },
    {
        "title": "E-Voting",
        "links": [
            { "title": "CDSL", "url": "https://evoting.cdslindia.com/Evoting/EvotingLogin" },
            { "title": "NSDL", "url": "https://www.evoting.nsdl.com/" }
        ]
    },
    {
        "title": "Investor Charter",
        "links": [
            { "title": "Mission & Vision", "url": "https://www.hdfcsec.com/mission-and-vision-statement-for-investors" },
            { "title": "Investor Complaints", "url": "https://www.hdfcsec.com/investor-charter-and-number-of-complaints" },
            { "title": "Raise Ticket", "url": "https://www.hdfcsec.com/products/FAQ/933" }
        ]
    },
    {
        "title": "HDFC SKY",
        "links": [
            { "title": "Share Market App", "url": "https://play.google.com/store/apps/details?id=com.cloudtradetech.sky" },
            { "title": "HDFC SKY", "url": "https://hdfcsky.com/" }
        ]
    },
    {
        "title": "Investors",
        "links": [
            { "title": "Investors", "url": "https://www.hdfcsec.com/investors" }
        ]
    },
    {
        "title": "Mandatory Information",
        "links": [
            { "title": "Mandatory Information", "url": "https://www.hdfcsec.com/mandatory-information" },
            { "title": "Authorised Person Details", "url": "https://hdfcsec.com/authorised-person-details" }
        ]
    },
    {
        "title": "SMARTODR",
        "links": [
            { "title": "SMARTODR", "url": "https://smartodr.in" },
            { "title": "SEBI circular", "url": "https://www.hdfcsec.com/sebi-circular" }
        ]
    },
    {
        "title": "EMPLOYEES & PARTNERS",
        "links": [
            { "title": "HDFC Life InstaSIP", "url": "https://instasip.hdfclife.com/hsl" },
            { "title": "Insurance Portal Login", "url": "https://www.hdfcsec.com/insurance-rmlogin" }
        ]
    }
]
