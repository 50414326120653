import { useState, useEffect } from 'react'
import Card from '../../component/Card';
import { API_CALL } from '../../utilities/function';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';



const OTP = ({ timer, twoFA, setAuthorize, setIsOTP, setRequestToken, setCallbackUrl, loginID, setTermsAndConditions, setOpenDisclaimer , setCheckAuthorize }) => {
    const otpArr = new Array(6).fill('')
    const [otp, setOtp] = useState(otpArr);
    const [mobileOTP, setMobileOTP] = useState([]);
    const [seconds, setSeconds] = useState(30);
    const [resend, setResend] = useState(false);
    const [question, setQuestion] = useState('');
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });



    const newArr = new Array(9).fill(0);



    //For Sending OTP API Call
    useEffect(() => {
        const enteredOtp = otp.join('');

        //Handling OTP Request
        const sendAns = async (answer) => {
            const params = {
                'answer': answer
            }
            const response = await API_CALL('/twofa/validate', 'post', '', '', params)
            if (!response.hasOwnProperty('data')) {

                if (!isTabletOrMobile) {
                    document.getElementById(`otp-input-0`).focus()
                }
            }
            setCheckAuthorize(response.data.authorised)
            if (response?.data?.authorised === false) {
                if (response?.data?.requestToken !== null && response?.data?.requestToken !== undefined) {
                    
                    setAuthorize(true);
                    setTermsAndConditions(response.data.termsAndConditions);
                    setRequestToken(response.data.requestToken);
                    setCallbackUrl(response.data.callbackUrl);
                }
            }
            else if (response?.data?.authorised) {
                if (response?.data?.requestToken !== null && response?.data?.requestToken !== undefined) {
                    setAuthorize(false);
                    setOpenDisclaimer(true);
                    setTermsAndConditions(response.data.termsAndConditions);
                    setRequestToken(response.data.requestToken);
                    setCallbackUrl(response.data.callbackUrl);
                }

            } else {
                // console.log(response)
                // toast.error("You've entered a Wrong OTP");
                toast.error("You've entered an Invalid OTP");
                if (!isTabletOrMobile) {
                    document.getElementById(`otp-input-0`).focus()
                }
                setOtp(otpArr)
            }
        };


        if (enteredOtp.trim().length === otp.length) {
            sendAns(enteredOtp);
        }
    }, [otp]);


    //For Resending OTP
    useEffect(() => {
        if (timer === null) {
            setSeconds(5);
            return;
        }
        setSeconds(30)
        if (twoFA === null || twoFA === undefined) {
            setQuestion({

                question: 'Enter OTP'
            })
            toast.error('Problem in getting 2FA Code')
            return;
        }

        setQuestion({

            question: twoFA.questions[0].question
        })

    }, []);

    //timer
    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(prevSeconds => {
                if (prevSeconds === 0) {
                    setResend(true)
                    return 0;
                }
                else if (prevSeconds < 0) {
                    setResend(false);
                }
                else {
                    return prevSeconds - 1;
                }
            });
        }, 1000);

    }, []);



    //Handling OTP for Mobile
    const handleButtonClick = (item) => {

        let arr = [...otp];
        if (arr[0] !== "") {
            return;
        }
        arr.push(item);
        arr.shift();

        setOtp(arr)

    }

    const handleBackspace = () => {
        let arr = [...otp];

        arr.pop();
        arr.unshift('')
        setOtp(arr);

    }

    //Handling OTP Input for Desktop
    const handleChange = (index, value) => {
        if (isNaN(value) || value.length > 1) {
            return;
        }

        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (index < otp.length - 1 && value !== '') {
            document.getElementById(`otp-input-${index + 1}`).focus();
        }
    };

    const handleKeyDown = (index, value, key) => {
        if (key === 'Backspace' && value === '') {

            if (index > 0) {
                const previousInput = document.getElementById(`otp-input-${index - 1}`);
                if (previousInput) {
                    previousInput.focus();
                }
            }
        }
    };

    const resendOTP = async () => {
        const response = await API_CALL('/twofa/resend', 'get');
        if (!response.hasOwnProperty('data') || response === undefined) {
            toast.error('There has been problem in sending OTP');
            return;
        }
        setSeconds(30);
        setResend(false);
        toast.success('OTP sent Successfully');

    }


    return (
        <>
            <div className="lg:w-full max-lg:w-full h-full flex items-center justify-center">
                <div className='h-full max-lg:h-screen w-full flex flex-col lg:items-center max-lg:items-start'>
                    {/* <div className="w-full max-lg:mb-5">
                        <span onClick={() => setIsOTP(false)} className="material-symbols-outlined text-Dark-Blue-80 cursor-pointer">
                            arrow_back
                        </span>
                    </div> */}
                    <div className="flex flex-col gap-5 max-lg:w-full">
                        <div className="text-center">
                            <h2 className="font-black text-Dark-Blue-80 text-[32px]">
                                Enter the {otpArr.length} Digit OTP
                            </h2>
                            <p className='text-[12px] font-semibold text-Grey-50'>
                                OTP sent on your registered Email & Phone number
                            </p>

                        </div>
                        {
                            !isTabletOrMobile &&
                            <div className="flex w-full justify-between">
                                {otp.map((digit, index) => {
                                    return (
                                        <input
                                            key={index}
                                            type="text"
                                            className={`outline-none border ${otp[index].length > 0 ? 'border-green-400 border-2' : ' border-grey-300'}  w-[48px] h-[48px] border-1 bg-transparent  p-2 text-center rounded-md placeholder:text-Grey-50`}
                                            id={`otp-input-${index}`}
                                            maxLength={1}
                                            autoComplete='off'
                                            value={digit}
                                            onChange={(e) => handleChange(index, e.target.value)}
                                            onKeyDown={(e) => handleKeyDown(index, e.target.value, e.key)}
                                        />
                                    )
                                })}
                            </div>
                        }
                        {isTabletOrMobile &&
                            <div className='w-full flex justify-center max-lg:mt-8'>
                                <div className="flex flex-wrap gap-2 w-full justify-center">
                                    {otp.map((digit, index) => {
                                        return (
                                            <span
                                                key={index}
                                                className={`outline-none flex items-center justify-center border text-Dark-Blue-80 max-lg:w-[40px] max-lg:h-[40px] text-[39px] w-[68px] h-[68px] border-1 bg-transparent  p-2 text-center rounded-md placeholder:text-Grey-50`}
                                            >
                                                {otp[otpArr.length - index - 1] !== '' ? "•" : ''}
                                            </span>
                                        )
                                    })}
                                </div>
                            </div>
                        }
                        <div className='flex justify-center w-full'>
                            <p className='text-sm text-Dark-Blue-60 max-lg:text-center '>
                                {resend
                                    ? (<b onClick={resendOTP} className='text-Primary-Blue-100 text-sm ml-2 cursor-pointer'>
                                        Resend OTP
                                    </b>)
                                    : <b>Resend in {seconds} sec</b>}
                            </p>
                        </div>
                    </div>
                    {
                        isTabletOrMobile && <div className='w-full mt-8'>
                            <div className='grid grid-cols-3 gap-x-5 gap-y-3'>
                                {newArr.map((item, index) =>
                                    <div
                                        onClick={() => { handleButtonClick(index + 1) }}
                                        className='text-lg flex items-center justify-center font-bold rounded-md bg-gray-200 p-3'>
                                        {index + 1}
                                    </div>)}
                                <div></div>
                                <div
                                    onClick={() => { handleButtonClick(0) }}
                                    className='text-lg flex items-center justify-center font-bold rounded-md bg-gray-200 p-3'>0</div>
                                <div className='text-lg flex items-center justify-end font-bold rounded-md w-full px-3'>
                                    <span
                                        onClick={handleBackspace}
                                        class="material-symbols-outlined text-[60px]">
                                        backspace
                                    </span>
                                </div>

                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default OTP