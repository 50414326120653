import { useEffect, useState } from "react"
import Button from "../../component/Button"
import Card from "../../component/Card"
import { AuthoriseContent } from "../../utilities/constants"
import { API_CALL } from "../../utilities/function"
import { Logo } from "../../utilities/constants"
import { useMediaQuery } from "react-responsive"
import Check from './../../assets/Check.svg'
import Disclaimer from "./../../component/Disclaimer"

const Authorisation = ({ callbackUrl, requestToken, termsAndConditions, setAuthorize, setParams, setOpenDisclaimer, setIsOTP }) => {
    const [merchDetails, setMerchDetails] = useState({
        name: '',
        logo: ''
    })
    const [tnc, setTnc] = useState(termsAndConditions);

    useEffect(() => {
        const getImage = async () => {
            const response = await API_CALL('/merchant-details', 'get');

            if (response) {
                setMerchDetails(
                    {
                        name: response?.data?.merchantName,
                        logo: response?.data?.logo
                    }
                )
            } else {
                setMerchDetails(
                    {
                        name: '',
                        logo: ''
                    }
                )
            }
        }

        getImage();
    }, [])




    // const authorise = async (choice) => {
    // const params = {
    //     consent: choice,
    //     request_token: requestToken
    // }
    //     if (!choice) {
    //         setAuthorize(false);
    //         const response = await API_CALL('/authorise', 'get', '', params);
    //         return;
    //     }

    //     const response = await API_CALL('/authorise', 'get', '', params);
    //      const fetchedURL = `${response.data.callbackUrl}?requestToken=${requestToken}`;
    //     console.log('@@FetchedURL from Resp', fetchedURL);

    // if (response.data.callbackUrl && response.data.requestToken) {
    //     window.location.href = `${response.data.callbackUrl}?requestToken=${requestToken}`
    // }

    // }

    const authorise = async (choice) => {
        const params = {
            consent: choice,
            request_token: requestToken
        }
        setParams(params);
        if (!choice) {
            setAuthorize(false);
            setIsOTP(false);
            const response = await API_CALL('/authorise', 'get', '', params);
            return;
        } else {
            setAuthorize(false);
            setIsOTP(false);
            setOpenDisclaimer(true);
        }
    }

    return (
        <>

            <div className="h-full flex items-center justify-center ">
                <div className='lg:min-h-5/6 max-lg:h-fit max-lg:w-full flex flex-col items-center w-[1024px] '>
                    <div className="GradientBorder h-full ">
                        <div className='AuthoriseCard h-full p-[20px] justify-center self-center  flex flex-col gap-5 items-center '>
                            <h3 className="text-base max-lg:text-center text-Dark-Blue-80 font-black">
                                Permission required to authorise the app
                            </h3>
                            <div className="flex flex-col items-center justify-center gap-2 text-center">
                                {(merchDetails.name !== null && merchDetails.name.trim().length) > 0 &&
                                    <p className="text-Dark-Blue-80 font-medium">{merchDetails.name}</p>
                                }

                                <div className="flex">
                                    <div className="bg-blue-100  mr-[-5px] w-[60px] h-[60px] z-5 flex items-center justify-center rounded-full p-2 ">
                                        <img
                                            className="h-[60px] w-[60px] object-contain "
                                            src={Logo}
                                            alt="Merch_Logo"
                                        />
                                    </div>
                                    {
                                        (merchDetails.logo !== null && merchDetails.logo !== undefined && merchDetails.logo.trim().length > 0) &&
                                        <div className="bg-white z-1 w-[60px] h-[60px]   rounded-full p-3 ">
                                            <img
                                                className="h-[40px] w-[40px]"
                                                src={merchDetails.logo}
                                                alt="Merch_Logo"
                                            />
                                        </div>
                                    }
                                </div>


                            </div>
                            <div className="h-full w-full flex-col justify-center flex gap-4">
                                {tnc?.content.length > 0 &&
                                    tnc.content.map(item =>
                                        <div className="flex items-center gap-4 whitespace-pre-wrap">
                                            {/* <span className='material-symbols-outlined text-base text-Primary-Blue-100'>
                                            check_circle
                                        </span> */}
                                            <img src={Check} alt="" />
                                            {item.body}
                                        </div>)}
                            </div>
                            <div className="w-full text-center h-full flex flex-col gap-4 justify-end">
                                <p className="text-[12px] text-left leading-[18px]">
                                    By clicking Authorise you agree and consent to the above mentioned terms and conditions.
                                </p>
                                <div className="flex">
                                    <p onClick={() => { authorise(false); }} className="font-bold w-full flex items-center justify-center text-Primary-Blue-100 cursor-pointer">Cancel</p>
                                    <Button onClick={() => { authorise(true) }} className='w-full' text={'Authorise'} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Authorisation