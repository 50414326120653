import { useEffect, useState } from 'react'
import Card from '../../component/Card'
import { useMediaQuery } from 'react-responsive'
import { Dashboard_API_CALL } from '../../utilities/function'
import { toast } from 'react-toastify'
import { Logo } from '../../utilities/constants'
import { useNavigate } from 'react-router-dom'
import Input from '../../component/Input'
import Button from '../../component/Button'
import Navbar from '../../component/Navbar'
import OTP from './OTP'
import Modal from '../../component/Modal'
import { HelpBoxContent } from '../../utilities/constants'
import { Link } from 'react-router-dom'
import NewIRLogo from './../../assets/IRNewLogo.png'
import Footer from '../../component/Footer'
import Fade from '../../component/Fade'
// import TopEllipse from './../../assets/individualLoginEllipse.svg'

const IndividualLogin = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isCaptcha, setIsCaptcha] = useState({
    captchaIMG: '',
    captcha: false,
    captchaID: '',
    captchaAns: ''
  });
  const [isOTP, setIsOTP] = useState(false);
  const navigate = useNavigate();
  const [openHelpBox, setOpenHelpBox] = useState(false);

  const [clientId, setClientId] = useState('')
  const handleUsername = (event) => {
    setUsername(event.target.value)
  }

  const handlePassword = (event) => {
    setPassword(event.target.value)
  }

  useEffect(() => {
    if ((localStorage.accessToken !== null || localStorage.accessToken !== undefined) && localStorage.imIsLoggedIn) {
      navigate('/oapi/v1/dashboard/apps')
    }
  }, [])

  const handleLogin = async (event) => {
    // navigate('/dashboard/apps')
    event.preventDefault()
    if (username.trim().length === 0 || password.trim().length === 0) {
      toast.error('Kindly Enter Valid Credentials');
      return;
    }
    let data = {
      'username': username.toLocaleUpperCase(),
      'password': password,
    }
    let params = {
      username: username.toLocaleUpperCase(),
      password
    }

    if (isCaptcha.captcha) {
      params = {
        ...params,
        'X-Captcha-Id': isCaptcha.captchaID,
        'X-Captcha-Answer': isCaptcha.captchaAns
      }

      data = {
        ...data,
        'X-Captcha-Id': isCaptcha.captchaID,
        'X-Captcha-Answer': isCaptcha.captchaAns
      }
    }



    const response = await Dashboard_API_CALL('/im/login/validate', 'post', '', params, data);

    if (response === null || response === undefined || !response.hasOwnProperty('data')) {
      // toast.error("There has been an internal server issue");
      return;
    }

    if (response.data.alert === 'Password expired') {
      toast.warn(response.data.alert)
      return;
    }

    if (response.data.twoFAEnabled) {
      setIsOTP(true);
      // setLoginID(response.data.loginId)
      // setTimer(response.data.timer);
      setClientId(response.data.loginId)
      // setTwoFA(response.data.twofa);
      // setQuestionId(response.data.questionId)
      setIsCaptcha(
        {
          captchaIMG: '',
          captcha: '',
          captchaID: '',
          captchaAns: ''
        }
      )
    }

    if (response.data.recaptcha) {
      const response = await Dashboard_API_CALL('/im/captcha', 'get');
      setIsCaptcha(
        {
          captchaIMG: response.data.captcha,
          captcha: true,
          captchaID: response.data.captchaId,
          captchaAns: ''
        }
      )
    }
  }


  return (
    <>

      {openHelpBox && <Modal onClick={() => setOpenHelpBox(false)} className='p-5 lg:w-1/3 '>
        <div className="flex flex-col gap-5">
          <h2 className="text-lg  font-bold text-Dark-Blue-80">
            Trouble Logging in?
          </h2>
          <div className="px-3 py-6">
            <ul className="gap-7 flex flex-col">
              {HelpBoxContent.length > 0 && HelpBoxContent.map((item, index) =>
                <li key={index} className="flex gap-3 cursor-pointer items-center hover:text-Primary-Blue-100">
                  <span className="material-symbols-outlined text-Grey-50"> {item.icon} </span>
                  <Link target="_blank" to={item.link} className="font-semibold ">{item.label}</Link>
                </li>)}
            </ul>
          </div>
        </div>
      </Modal>}
      {!isTabletOrMobile && <Navbar />}
      <Fade>
        <div className='flex bg-[#F4F7FA] max-lg:h-screen  items-center justify-center'>
          <div className='lg:w-[1140px] flex max-lg:w-full'>
            <section className='min-h-screen lg:w-full gap-4 flex items-start flex-col justify-center max-lg:hidden'>
              <h1 className='text-Dark-Blue-80 text-[40px] leading-[52.2px] font-bold'>
                Welcome to,<br />
                InvestRight Individual API
              </h1>
              <p className='text-Dark-Blue-60'>
                Integrate apps through InvestRight APIs and <br />
                explore the limitless possibilities of investing
              </p>
            </section>
            <section className='lg:p-12 w-full flex flex-col items-center justify-center  bg-cover'>
              <div className="max-lg:w-full w-fit">
                <Card className=" shadow-lg min-h-full max-lg:min-h-screen max-lg:w-full flex-1 max-lg:bg-white  flex items-center  flex-col  py-10 max-lg:px-6 max-lg:py-10  h-full">
                  <div className="flex w-full items-center  max-lg:items-start px-5  max-lg:w-full flex-col gap-5 lg:pb-6">
                    {!isOTP && <>
                      <div className='mb-3'>
                        {/* <img src={Logo} alt="" /> */}
                      </div>
                      <div className="text-center max-lg:text-left ">
                        {isTabletOrMobile && <div className='mb-10 mt-10'>
                          <img src={NewIRLogo} />
                        </div>}
                        <h2 className="font-bold text-Dark-Blue-80 text-[30px] max-lg:mb-2 max-lg:leading-[42px]">
                          Login To <br className='lg:hidden' /> InvestRight API
                        </h2>
                        <p className="text-Dark-Blue-60 font-semibold text-sm max-lg:font-light">
                          Please enter Client ID to get started
                        </p>
                      </div>
                      <form
                        onSubmit={handleLogin}
                        className="flex flex-col gap-3 w-2/3 max-lg:w-full">
                        <Input
                          maxLength={30}
                          onChange={handleUsername}
                          value={username.toLocaleUpperCase()}
                          name={'Enter Client ID'}
                          placeholder='Client ID' />
                        <Input
                          maxLength={30}
                          onChange={handlePassword}
                          name={'Enter Password'}
                          password={password}
                          placeholder='Password'
                          type={'password'} />
                        <div>
                          {isCaptcha.captcha &&
                            <div className='flex gap-3'>
                              <div className=''>
                                <img src={`data:image/png; base64,${isCaptcha.captchaIMG}`} alt="Captcha" />
                              </div>
                              <div className='w-full'>
                                <Input
                                  placeholder='Enter Captcha Code'
                                  onChange={(event) => {
                                    setIsCaptcha((item) => ({ ...item, captchaAns: event.target.value }))
                                  }}
                                />
                              </div>
                            </div>
                          }
                        </div>
                        <Button
                          type={'submit'}
                          className={` ${(username.trim().length === 0 || password.trim().length === 0) ? 'opacity-[0.4]' : ''}`}
                          text={'Login'} />
                        <p
                          onClick={() => { setOpenHelpBox(true) }}
                          className='cursor-pointer font-semibold text-Primary-Blue-100 text-center'>
                          Need help?
                        </p>

                      </form>
                    </>}
                    {isOTP && <OTP clientId={clientId} setIsOTP={setIsOTP} />}
                  </div>
                </Card>
              </div>
            </section>
          </div>
        </div>
      </Fade>
      <Footer />
    </>
  )
}

export default IndividualLogin