
import './App.css';
import { BrowserRouter, Routes, Route, redirect, Mem } from 'react-router-dom';
import Landing from './page/Landing';
import Login from './page/Login/Login';
import MyApp from './page/Dashboard/MyApp';

import IndividualLogin from './page/Login2/IndividualLogin';
import CreateApp from './page/Dashboard/CreateApp';
import Dashboard from './page/Dashboard/Dashboard';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConnectedApps from './page/ConnectedApps/ConnectedApps';



function App() {
  return (
    <>
      <ToastContainer
        style={{ 'text-transform': 'capitalize' }}
      />
      <BrowserRouter>
        <Routes>
          {/* <Route path='*' element={<Landing />} /> */}
          <Route path='/' element={<Landing />} />
          <Route path='/oapi/v1/login' element={<Login />} />
          <Route path='/oapi/connected-apps' element={<ConnectedApps />} />
          <Route path='/oapi/v1/im/login' element={<IndividualLogin />} />
          <Route replace={true} path='/oapi/v1/dashboard' element={<Dashboard />} >
            <Route path='/oapi/v1/dashboard/apps' element={<MyApp />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
