import { useState } from 'react';
import Laptop from './../assets/World.png'
import Navbar, { checkActive, redirectTo } from '../component/Navbar';
import Footer from '../component/Footer';
import Card from '../component/Card';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, NavLink } from 'react-router-dom';

import { FeatureCardContent, FeatureCardContent2, TabsContent, Name } from '../utilities/constants';
import Button from '../component/Button';
import { HamburgerMenu } from '../component/Navbar';
import Fade from '../component/Fade';

const Landing = () => {
  const navigate = useNavigate();
  const [openHamburgerMenu, setOpenHamburgerMenu] = useState(false);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 })
  const Tabs = ({ img, imgHeading, index, content, handleClick, contentHeading }) => {
    return (
      <div className=' bg-[#F9FAFB] px-10 max-lg:px-7 py-12 rounded-[20px] flex flex-col gap-5  items-center  justify-start'>

        <img

          className='mt-[-20%] max-lg:[mt:[-40%]] lg:h-[200px] lg:w-[200px]' src={img} alt="" />

        <div className='flex flex-col gap-5 '>
          <div className='h-full'>
            <h1 className='text-lg max-lg:text-base font-bold'>{imgHeading}</h1>
            <h2 className='text-[16px] font-semibold'>{contentHeading}</h2>
          </div>
          <ul className='flex flex-col gap-3 list-disc'>
            {content.length > 0 && content.map(item => <li className='text-[16px] font-medium text-Dark-Blue-60'>{item}</li>)}
          </ul>
        </div>
      </div>
    )
  }


  const FeatureCard = ({ heading, text, img }) =>
    <div className={'w-full flex gap-3 flex-col max-lg:gap-2 justify-start  items-start border-[#F2F4F7] rounded-[20px] border-[7px] px-5 py-8'}>

      <img className='mb-2' src={img} alt="" />
      <h2 className='text-Dark-Blue-80 text-[22px] max-lg:text-base font-bold leading-[32px]'>{heading}</h2>
      <p className='text-Grey-80  text-sm font-normal max-lg:text-[16px]'>{text}</p>

    </div>

  const FeatureCard2 = ({ heading, text, subheading, isNew }) =>
    <div className={`w-full flex g-3 flex-col justify-start items-start bg-white px-6 pb-[26px] rounded-xl `}>
      {<span className={`text-[12px] mt-[42px] ${isNew ? '' : 'invisible'}  text-Primary-Blue-100 rounded-lg bg-[#EAECF5] font-bold py-1 px-3 mb-2 `}>Coming Soon</span>}
      {/* <span className={`${!isNew?'mt-[56px]':'hidden'}`}></span> */}
      <h2 className='text-black text-base font-bold leading-[32px]'>{heading}</h2>
      {/* <h3 className='text-base font-medium text-Dark-Blue-80'>{subheading}</h3> */}
      <p className='text-Grey-80  text-sm font-normal lg:w-5/6'>{text}</p>
    </div>

  const openNewWind = (type) => {
    if (type == 'uat') {
      window.location.href = '/oapi/v1/im/login'
    } else if (type == 'prod') {
      window.location.href = '/ir-docs'
    }
  }

  return (
    <>
      <div className='relative lg:h-[10vh] w-full flex'>
        <Navbar
          isLogin={localStorage.imIsLoggedIn}
          funcOpenHamburgerMenu={() => setOpenHamburgerMenu(!openHamburgerMenu)} />
      </div>
      {isTabletOrMobile &&
        (openHamburgerMenu &&
          <HamburgerMenu />
        )
      }
      <Fade>
        {/* #1 */}
        <section
          className='relative min-h-[100vh] max-lg:min-h-[85vh]  max-lg:justify-center  flex flex-col justify-start items-center'>

          <div className='min-h-[85vh] w-full flex items-center justify-center lg:px-[36px]'>
            <div className=' w-full flex justify-center  lg:px-20 max-lg:p-5 items-center  bg-[#F4F7FA]'>
              <div className='max-lg:w-full flex w-[1140px] items-center max-lg:flex-col-reverse rounded-[20px]'>
                <div className='w-full flex flex-col items-start justify-center'>
                  <div className='flex flex-col max-lg:w-full gap-2 max-lg:items-center max-lg:justify-center'>
                    <h1 className='font-bold leading-[52px] text-[42px] max-lg:text-center max-lg:text-[28px] max-lg:leading-[36.4px]'>
                      Connect Apps Using <br />
                      <span className='text-Primary-Blue-100'> Open API </span>
                    </h1>
                    <p className='text-[16px] text-Dark-Blue-60 max-lg:text-center'>
                      Integrate apps through InvestRight APIs <br className='lg:hidden' /> and explore the <br className='max-lg:hidden' />
                      limitless  possibilities of investing
                    </p>
                  </div>
                  <Button
                    onClick={() => { openNewWind('prod') }}
                    className='px-10 mt-8 max-lg:w-full max-lg:self-center'
                    text={'Get Started'} />
                </div>
                <div className='w-full flex items-center justify-end max-lg:justify-center'>
                  <img className='' src={Laptop} alt="Laptop" />
                </div>
              </div>
            </div>
          </div>

        </section>
        {/* #2 */}
        <section className='min-h-screen w-full flex items-center justify-center pb-10'>
          <div className='w-[90vw] max-lg:w-full max-lg:p-5 flex flex-col justify-start gap-10 items-center'>
            <h1 className='Heading max-lg:text-lg max-lg:pt-10'>
              What Makes InvestRight Limitless
            </h1>
            <div className='grid w-full max-lg:flex max-lg:flex-wrap grid-cols-3 gap-x-8 gap-y-7 grid-cols-gap-2'>
              {FeatureCardContent.length > 0 &&
                FeatureCardContent.map((item) => (
                  <FeatureCard
                    heading={item.heading}
                    img={item.img}
                    text={item.text}
                  />))
              }
            </div>
          </div>
        </section>
        {/* #3 */}
        <section className='min-h-screen max-lg:min-h-fit mb-[100px] max-lg:mb-0 w-full flex items-center justify-center'>
          <div className='w-[1140px] max-lg:w-full max-lg:p-5 flex flex-col gap-9'>
            <div className='min-h-[10vh] w-full justify-center flex items-end'>
              <h1 className='Heading max-lg:text-lg  max-lg:leading-[130%] lead  text-center'>
                Unlock a World of Possibilities <br className='max-lg:hidden' /> with Our API</h1>
            </div>
            <div className='flex max-lg:flex-col gap-7 min-h-[85vh] items-center'>
              <div className='flex max-lg:flex-col gap-7'>

                {TabsContent.length > 0 &&
                  TabsContent.map(item =>
                  (<Tabs
                    img={item.img}
                    content={item.content}
                    imgHeading={item.imgHeading}
                    contentHeading={item.contentHeading}
                  />))
                }
              </div>
            </div>
          </div>
        </section>
        {/* #4 */}
        <section className='min-h-screen  flex justify-center items-center mb-10'>
          <div className='  flex justify-center items-center min-h-screen w-full lg:px-[36px]'>
            <div className='w-full bg-Primary-Blue-100 py-20 px-10 lg:rounded-2xl bg-maskedBG bg-no-repeat bg-cover flex flex-col gap-9'>
              <h1 className='Heading lg:text-white max-lg:text-white text-center max-lg:text-lg'>Everything you need is now under <br className='max-lg:hidden' /> one platform</h1>
              <div className='grid grid-cols-3 gap-8 max-lg:flex flex-col'>
                {
                  FeatureCardContent2.length > 0 &&
                  FeatureCardContent2.map((item) => (
                    <FeatureCard2
                      heading={item.heading}
                      isNew={item.isNew}
                      subheading={item.subheading}
                      text={item.text}
                    />
                  ))
                }
              </div>
            </div>
          </div>
        </section>
      </Fade>

      {/* #5 */}
      {/* <section className='min-h-screen flex justify-center item py-[100px]'>
        <div className='w-[90vw] flex-col'>
          <div className='flex gap-5'>
            <div className='w-1/3'>
              <Card className='Green flex items-center justify-center'>
                <h1 className='font-bold text-[48px] text-Dark-Blue-80 leading-normal text-center'>Pricing<br /> and <br /> API details</h1>
              </Card>
            </div>
            <div className='w-full flex flex-col gap-5'>
              <div className='flex gap-5'>
                <Card className={'w-3/6'}>
                  <h1 className='text-[120px] font-bold text-Dark-Blue-80 leading-[162px]'>₹0</h1>
                  <p className='text-lg font-bold text-Dark-Blue-80'>Free Trading APIs</p>
                  <p className='text-Dark-Blue-80 text-base font-sm'>Explore cost-free APIs for <br /> seamless trades</p>
                </Card>
                <Card className={'w-full flex gap-6'}>
                  <div>
                    <h1 className='text-[120px] font-bold text-Dark-Blue-80 leading-[162px]'>₹0</h1>
                    <p className='text-lg font-bold text-Dark-Blue-80'>Market Data API</p>
                    <p className='text-Dark-Blue-80 text-base font-sm'>Real-time market insights for informed decisions</p>
                  </div>
                  <div>
                    <h1 className='text-[120px] font-bold text-Dark-Blue-80 leading-[162px]'>25</h1>
                    <p className='text-lg font-bold text-Dark-Blue-80'>F&O or Equity orders per month</p>
                  </div>
                </Card>
              </div>
              <Card>
                <div className='flex justify-between'>
                  <p className='text-lg font-bold text-Dark-Blue-80'>Free Trading APIs</p>
                  <p className='text-lg font-bold text-Dark-Blue-80'>₹25 per orders</p>
                </div>
                <p className='text-Dark-Blue-80 font-sm'>Quick adjustments to adapt to market changes</p>
              </Card>
            </div>
          </div>
          <div className='flex gap-5 w-full mt-5'>
            <Card className={'w-full flex flex-col gap-5'}>
              <div >
                <div className='flex justify-between'>
                  <p className='text-lg font-bold text-Dark-Blue-80'>Market Feed</p>
                  <p className='text-lg font-bold text-Dark-Blue-80'>Real Time</p>
                </div>
                <p className='text-Dark-Blue-80 font-sm'>Live updates on market movements for smarter decisions</p>
              </div>
              <span className='w-full h-[1px] bg-White'></span>
              <div>
                <div className='flex justify-between'>
                  <p className='text-lg font-bold text-Dark-Blue-80'>API Response latency</p>
                  <p className='text-lg font-bold text-Dark-Blue-80'>100ms</p>
                </div>
                <p className='text-Dark-Blue-80 font-sm'>Lightning-fast API responses in just 100ms for seamless performance</p>
              </div>
            </Card>
            <Card className={'w-full flex flex-col gap-5'}>
              <div >
                <div className='flex justify-between'>
                  <p className='text-lg font-bold text-Dark-Blue-80'>Order Placement</p>
                  <p className='text-lg font-bold text-Dark-Blue-80'>10 orders/Sec
                    500 order/Min</p>
                </div>
                <p className='text-Dark-Blue-80 font-sm'>Efficient and precise 10 order execution/ sec</p>
              </div>
              <span className='w-full h-[1px] bg-White'></span>
              <div>
                <div className='flex justify-between'>
                  <p className='text-lg font-bold text-Dark-Blue-80'>Max order limit</p>
                  <p className='text-lg font-bold text-Dark-Blue-80'>5000 orders/Day</p>
                </div>
                <p className='text-Dark-Blue-80 font-sm'>Controlled trading volumes for risk management</p>
              </div>
            </Card>
          </div>
        </div>
      </section> */}
      <Footer />
    </>
  )
}

export default Landing