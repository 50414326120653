import { useState } from 'react'
import Navbar, { HamburgerMenu, checkActive, redirectTo } from '../../component/Navbar';
import ConnectedappsImg from './../../assets/connectedapps.png'
import AlphanitiImg from './../../assets/alphaniti.png'
import InstaoptionImg from './../../assets/InstaOptions.svg'
import { NavLink } from 'react-router-dom'
import Card from '../../component/Card';
import Button from '../../component/Button';
import { useMediaQuery } from 'react-responsive';
import Footer from '../../component/Footer';
import Fade from '../../component/Fade';

const ConnectedApps = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
    const [openHamburgerMenu, setOpenHamburgerMenu] = useState(false);
    const openNewWind = (type) => {
        if (window.location.host === 'uat-developer.hdfcsec.com') {
            window.open('https://uat-developer.hdfcsec.com/oapi/v1/login?api_key=b81e490ac87847a5b6dae70676721e20')
        } else{
            window.open('https://developer.hdfcsec.com/oapi/v1/login?api_key=91fb600e811944338ddd1b3cef2f7e91')
        }
    }
    return (
        <>
            <div className='relative lg:h-[10vh] w-full flex'>
                <Navbar
                    isLogin={localStorage.imIsLoggedIn}
                    funcOpenHamburgerMenu={() => setOpenHamburgerMenu(!openHamburgerMenu)} />
            </div>
            {isTabletOrMobile &&
                (openHamburgerMenu &&
                    <HamburgerMenu />
                )
            }
            <Fade>
                <section className='relative min-h-[100vh] max-lg:min-h-[85vh]  max-lg:justify-center  flex flex-col justify-start items-center'>
                    <div className='min-h-[85vh] w-full flex items-start pt-10 max-lg:pt-24 justify-center pb-10'>
                        <div className='lg:w-[1140px] max-lg:w-full max-lg:flex-col flex-col gap-7  lg:px-20 max-lg:p-5 flex items-center flex-0 rounded-[20px] '>
                            <div className='flex  flex-col items-center justify-center w-full'>

                                <img src={ConnectedappsImg} alt="connected apps image" />

                                <div className='flex flex-col lg:gap-3 justify-center items-center'>
                                    <h1 className='font-bold text-[42px] max-lg:text-[36px]'>Connected Apps</h1>
                                    <p className='text-[18px] text-Grey-80 max-lg:text-center'>Partners building on top of our API Infrastructure</p>
                                </div>
                            </div>
                            <div className='flex max-lg:flex-col items-start h-full gap-5'>
                                <Card className={'flex flex-col gap-5 h-inherit flex-grow-1 items-start shadow-lg w-full'}>
                                    <div className='w-full flex items-center justify-center'>
                                        <img width={200} src={InstaoptionImg} alt="" />
                                    </div>
                                    <p className='text-Grey-80'>
                                        InstaOptions is an advanced options trading platform which is designed to elevate traders' success by providing essential tools and insights.<br />

                                    </p>
                                    <ul>
                                        <b className='text-Grey-80 mb-2'>Key Features:</b>
                                        <li className='text-Grey-80'><b>Option Pricing Analysis:</b> Gain deep insights into option pricing to identify lucrative trading opportunities. </li>
                                        <li className='text-Grey-80'><b>Market Sentiment:</b> Understand market trends and sentiment to make informed decisions.</li>
                                        <li className='text-Grey-80'><b>Open Interest Trends:</b> Monitor open interest to gauge market activity and potential price movements. </li>
                                        <li className='text-Grey-80'><b>Risk Management:</b> Utilize advanced tools to manage risks effectively.  </li>
                                    </ul>
                                    <Button
                                        onClick={() => { openNewWind('prod') }}
                                        className={'w-full'} outlined={true}>
                                        Visit Now
                                    </Button>
                                </Card>
                                {/* <Card className={'flex flex-col gap-5 h-inherit flex-grow-1 items-start shadow-lg w-full'}>
                            <div className=''>
                                <img width={160} src={AlphanitiImg} alt="" />
                            </div>
                            <p className='text-Grey-80'>
                                Alphaniti is a SEBI registered advisory platform which provides single stock and basket of stocks recommendations.
                            </p>
                            <span className='cursor-default rounded-lg w-full bg-Primary-Blue-10 text-Primary-Blue-100 py-2  text-center text-[16px] max-lg:py-4 font-bold px-3 mb-2'>Coming Soon</span>
                        </Card> */}
                            </div>
                        </div>
                    </div>
                </section>
            </Fade>
            <Footer />
        </>
    )
}

export default ConnectedApps