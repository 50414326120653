import React, { useState, useEffect } from 'react'
import Card from '../../component/Card'
import Input from '../../component/Input'
import QR from './../../assets/qr.png';
import visibility from './../../assets/visibility.svg'
import visibility_off from './../../assets/visibility_off.svg'
import Button from '../../component/Button'
import Disclaimer from './Disclaimer'
import { Name, Logo } from '../../utilities/constants'
import { toast } from 'react-toastify'
import { API_CALL } from '../../utilities/function'
import { useMediaQuery } from 'react-responsive'
import OTP from './OTP';

const Credentials = ({ setCheckAuthorize, setIsOTP, setLoginID, setOpenHelpBox, isOTP, setAuthorize, setTermsAndConditions, setRequestToken, setCallbackUrl, setOpenDisclaimer }) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isCaptcha, setIsCaptcha] = useState({
        captchaIMG: '',
        captcha: false,
        captchaID: '',
        captchaAns: ''
    });
    const [merchDetails, setMerchDetails] = useState({
        name: '',
        logo: ''
    })
    const [timer, setTimer] = useState(30);
    const [twoFA, setTwoFA] = useState(null)

    useEffect(() => {
        const getImage = async () => {
            const response = await API_CALL('/merchant-details', 'get');

            if (!response.data?.merchantName && !response.data?.logo) {
                setMerchDetails(
                    {
                        name: '',
                        logo: ''
                    }
                )
            }

            setMerchDetails(
                {
                    name: response.data?.merchantName,
                    logo: response.data?.logo
                }
            )
        }

        getImage();
    }, [])


    const handleUsername = (event) => {
        setUsername(event.target.value)
    }

    const handlePassword = (event) => {
        setPassword(event.target.value)
    }

    const handleLogin = async (event) => {
        event.preventDefault()
        if (username.trim().length === 0 || password.trim().length === 0) {
            toast.error('Kindly Enter Valid Credentials');
            return;
        }
        let data = {
            'username': username.toLocaleUpperCase(),
            'password': password,
        }
        let params = {
            username: username.toLocaleUpperCase(),
            password
        }

        if (isCaptcha.captcha) {
            params = {
                ...params,
                'X-Captcha-Id': isCaptcha.captchaID,
                'X-Captcha-Answer': isCaptcha.captchaAns
            }

            data = {
                ...data,
                'X-Captcha-Id': isCaptcha.captchaID,
                'X-Captcha-Answer': isCaptcha.captchaAns
            }
        }



        const response = await API_CALL('/login/validate', 'post', '', params, data);

        if (response === null || response === undefined || !response.hasOwnProperty('data')) {
            // toast.error("There has been an internal server issue");
            return;
        }

        if (response.data.alert === 'Password expired') {
            toast.warn(response.data.alert)
            return;
        }

        if (response.data.twoFAEnabled) {
            setIsOTP(true);
            setLoginID(response.data.loginId)
            setTwoFA(response.data.twofa);
            setIsCaptcha(
                {
                    captchaIMG: '',
                    captcha: '',
                    captchaID: '',
                    captchaAns: ''
                }
            )
        }

        if (response.data.recaptcha) {
            const response = await API_CALL('/captcha', 'get');
            setIsCaptcha(
                {
                    captchaIMG: response.data.captcha,
                    captcha: true,
                    captchaID: response.data.captchaId,
                    captchaAns: ''
                }
            )
        }
    }

    return (
        <>
            {!isTabletOrMobile && <div className='flex flex-col  justify-center w-full gap-3'>
                <h1 className='leading-[57.2px] text-Dark-Blue-80 font-black text-[44px]'>
                    Welcome to,<br />
                    {Name}
                </h1>
                <p className='text-base text-Grey-80 font-bold'>
                    Investment Just got an Upgrade.<br />
                    Experience {Name}
                </p>
            </div>
            }
            <div className="lg:w-4/5 min-h-full max-lg:h-full max-lg:w-full">
                <Card className="shadow-lg min-h-full max-lg:min-h-screen  max-lg:h-max flex-1 max-lg:bg-white  flex items-center  flex-col  py-6 max-lg:px-6 max-lg:py-10  h-full">
                    {isOTP ? (
                        <OTP
                            setCheckAuthorize={setCheckAuthorize}
                            setCallbackUrl={setCallbackUrl}
                            twoFA={twoFA}
                            timer={timer}
                            setOpenDisclaimer={setOpenDisclaimer}
                            setAuthorize={setAuthorize}
                            setIsOTP={setIsOTP}
                            setTermsAndConditions={setTermsAndConditions}
                            setRequestToken={setRequestToken}
                        />
                    ) : (
                        <div className="flex w-full items-center UpperEllipse  max-lg:items-start px-5  max-lg:w-full flex-col gap-3">
                            <div className='mb-3'>
                                <img src={Logo} alt="" />
                            </div>
                            <img className='mt-4' width={125} src={merchDetails.logo} />
                            <div className="text-center max-lg:text-left">
                                <h2 className="font-black text-Dark-Blue-80 text-[30px] max-lg:leading-[42px]">
                                    Login To<br className='lg:hidden' /> {merchDetails.name}
                                </h2>
                                <p className="text-Grey-50 font-semibold text-sm">
                                    Please enter Client ID to get started
                                </p>
                            </div>
                            <form
                                onSubmit={handleLogin}
                                className="flex flex-col gap-3 w-full ">
                                <Input
                                    onChange={handleUsername}
                                    value={username.toLocaleUpperCase()}
                                    name={'Enter Client ID'}
                                    placeholder='Client ID' />
                                <div className=' relative flex items-center justify-center w-full'>
                                    <Input
                                        className='w-full'
                                        onChange={handlePassword}
                                        name={'Enter Password'}
                                        password={password}
                                        placeholder='Password'
                                        type={showPassword ? 'text' : 'password'} />
                                    <div className='absolute flex items-center justify-center top-[5px] right-[8px] h-full'>
                                        {showPassword ?
                                            <img
                                                onClick={() => { setShowPassword(false) }}
                                                className='cursor-pointer'
                                                src={visibility} alt="" />
                                            :
                                            <img
                                                onClick={() => { setShowPassword(true) }}
                                                className='cursor-pointer'
                                                src={visibility_off} alt="" />
                                        }
                                    </div>

                                    {/* <img src={visibility_off} alt="" /> */}
                                </div>
                                <div>
                                    {isCaptcha.captcha &&
                                        <div className='flex gap-3'>
                                            <div className=''>
                                                <img src={`data:image/png; base64,${isCaptcha.captchaIMG}`} alt="Captcha" />
                                            </div>
                                            <div className='w-full'>
                                                <Input
                                                    placeholder='Enter Captcha Code'
                                                    onChange={(event) => {
                                                        setIsCaptcha((item) => ({ ...item, captchaAns: event.target.value }))
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                                <Button
                                    className={` ${(username.trim().length === 0 || password.trim().length === 0) ? 'bg-Text-Disabled' : ''}`}
                                    text={'Get Started'} />
                                <p
                                    onClick={() => setOpenHelpBox(true)}
                                    className='cursor-pointer font-semibold text-Primary-Blue-100 text-center'>
                                    Need help?
                                </p>

                            </form>
                        </div>
                    )}

                    <div className="h-full pb-5 flex items-end">
                        <Disclaimer />
                    </div>
                </Card>
            </div>
        </>
    )
}

export default Credentials